<template>
  <div class="container">
    <dimmer :active="loading" class="row-block">
      <div class="card mb-0">
        <div class="card-header d-flex justify-content-between align-items-center sticky-header">
          <div class="d-flex align-items-center gap-10">
            <router-link
              :to="`/users/${data.customer_id}`"
              class="btn btn-secondary"
              data-test="button-back">
              <i class="fe fe-chevron-left"></i> Back
            </router-link>
            <h3 class="card-title">
              {{data.name ? data.name : 'NA'}}
            </h3>
          </div>
        </div>
        <div class="card-body">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Roles</div>
            </div>
            <div class="card-body">
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">List of roles</label>
                <div class="col-sm-7">
                  <autocomplete
                    v-model="selectedRole"
                    :list.sync="roleList"
                    :nextPage="nextRoles"
                    label="name"
                    placeholder="Select a role"
                    :clearable="false"
                    @input="handleRoleSelected"/>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Assigned roles</label>
                <div class="col-sm-7">
                  <h-chip-list
                    class="font-weight-bold"
                    :value="data.roles"
                    @remove="removeRole">
                    <template #no-data-placeholder>
                      No roles assigned
                    </template>
                  </h-chip-list>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Additional permissions</div>
            </div>
            <div class="card-body">
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">List of permissions</label>
                <div class="col-sm-7">
                  <autocomplete
                    v-model="selectedPermission"
                    :list.sync="permissionList"
                    :nextPage="nextPermissions"
                    label="name"
                    placeholder="Select a permission"
                    :clearable="false"
                    @input="handlePermissionSelected"/>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Permissions</label>
                <div class="col-sm-7">
                  <h-chip-list
                    class="font-weight-bold"
                    :value="directPermissions"
                    @remove="removePermission">
                    <template #no-data-placeholder>
                      No additional permissions assigned
                    </template>
                  </h-chip-list>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Permissions overview</div>
            </div>
            <div class="card-body">
              <div class="row d-flex">
                <div class="col-sm-12">
                  <ul>
                    <li
                      v-for="(value, key) in nestedAllPermissions"
                      :key="key"
                      class="mb-2">
                      <strong class="text-capitalize">
                        {{lowerCase(key)}}
                      </strong>
                      <ul class="my-b">
                        <li v-for="item in value" :key="item">
                          {{item}}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dimmer>
  </div>
</template>

<script>
import Autocomplete from '@/components/Autocomplete';
import HChipList from '@/components/ui/HChipList';
import {permissions, roles, users} from '@/services';

export default {
  name: 'UserRolesAndPermissions',
  components: {
    Autocomplete,
    HChipList,
  },
  data() {
    return {
      loading: false,
      name: '',
      data: {},
      roleList: [],
      selectedRole: null,
      permissionList: [],
      selectedPermission: null,
    };
  },
  computed: {
    allPermissions() {
      if (!this.data.permissions) return [];
      return [...this.data.permissions.direct, ...this.data.permissions.inherited];
    },
    nestedAllPermissions() {
      const allPermissionNames = this.allPermissions.map(it => it.name);
      return allPermissionNames.reduce((acc, val) => {
        const key = val.split('.')[0];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(val);
        return acc;
      }, {});
    },
    directPermissions() {
      if (!this.data.permissions) return [];
      return this.data.permissions.direct;
    },
  },
  async created() {
    this.fetchData();
    this.roleList = await this.nextRoles(1, []);
    this.permissionList = await this.nextPermissions(1, []);
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const response = await users.getById(this.$route.params.id, {
          path: '/roles-permissions',
        });
        this.data = response.item;
      }
      finally {
        this.loading = false;
      }
    },
    async nextRoles(page, query) {
      const params = {
        column: 'name',
        direction: 'asc',
        limit: 0,
        page,
      };
      if (query.length) params.query = query;
      const result = await roles.get({
        params: {
          limit: params.limit,
          page,
          with_count: 'permissions',
          query: params.query,
        },
      });
      return result;
    },
    async handleRoleSelected(role) {
      const exist = this.data.roles.find(it => it.id === role.id);
      if (exist) {
        this.$toasted.error(`Role "${role.name}" already assigned to user`);
        this.selectedRole = null;
        return;
      }
      const confirm = window.confirm(`Are you sure you want to assign the role "${role.name}"?`);
      if (confirm) {
        try {
          this.loading = true;
          await users.post({}, {
            path: `/${this.data.id}/role/${role.id}`,
          });
          this.selectedRole = null;
          this.fetchData();
        }
        catch (error) {
          this.$toasted.error(`Something went wrong, ${Object.values(error.response?.data?.errors || [error.message]).join(',')}`);
          this.loading = false;
        }
        return;
      }
      this.selectedRole = null;
    },
    async removeRole(role) {
      const confirm = window.confirm(`Are you sure you want to remove the role "${role.name}"?`);
      if (confirm) {
        try {
          this.loading = true;
          await users.deleteById(this.data.id, {
            path: `/role/${role.id}`,
          });
          this.fetchData();
        }
        catch (error) {
          this.$toasted.error(`Something went wrong, ${Object.values(error.response?.data?.errors || [error.message]).join(',')}`);
          this.loading = false;
        }
      }
    },
    async nextPermissions(page, query) {
      const params = {
        column: 'name',
        direction: 'asc',
        limit: 20,
        page,
      };
      if (query.length) params.query = query;
      const result = await permissions.get({
        params: {
          limit: params.limit,
          page,
          with_count: 'permissions',
          query: params.query,
        },
      });
      return result;
    },
    async handlePermissionSelected(permission) {
      const exist = this.allPermissions.find(it => it.id === permission.id);
      if (exist) {
        this.$toasted.error(`Role "${permission.name}" already assigned to user`);
        this.selectedPermission = null;
        return;
      }
      const confirm = window.confirm(`Are you sure you want to assign the permission "${permission.name}"?`);
      if (confirm) {
        try {
          this.loading = true;
          await users.post({}, {
            path: `/${this.data.id}/permission/${permission.id}`,
          });
          this.selectedPermission = null;
          this.fetchData();
        }
        catch (error) {
          this.$toasted.error(`Something went wrong, ${Object.values(error.response?.data?.errors || [error.message]).join(',')}`);
          this.loading = false;
        }
        return;
      }
      this.selectedPermission = null;
    },
    async removePermission(permission) {
      const confirm = window.confirm(`Are you sure you want to remove the permission "${permission.name}"?`);
      if (confirm) {
        try {
          this.loading = true;
          await users.deleteById(this.data.id, {
            path: `/permission/${permission.id}`,
          });
          this.fetchData();
        }
        catch (error) {
          this.$toasted.error(`Something went wrong, ${Object.values(error.response?.data?.errors || [error.message]).join(',')}`);
          this.loading = false;
        }
      }
    },
  },
};
</script>
